export const getHrefWithSameQueryParams = (url) =>
  `${url}${window.location.search}`;

export const setQueryParams = (params) => {
  const url = new URL(window.location);
  Object.keys(params).forEach((key) => url.searchParams.set(key, params[key]));
  window.history.replaceState({}, "", url);
}

export const getQueryParam = (param) => {
  const url = new URL(window.location);
  const params = {};
  url.searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return params[param];
}

export
const getRandomOption = (options, name ) => {
  const currentWord = getQueryParam(name);
  if (currentWord && options.includes(currentWord)) return currentWord;
  const word = options[Math.floor(Math.random() * options.length)];
  setQueryParams({ founders: word });
  return word;
};
