import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ErrorBoundary } from 'react-error-boundary';

import Header from './pages/sections/Header';
import Footer from './pages/sections/Footer';
import { NotFound } from './components/NotFound';
import { AppRoutes } from './AppRoutes';
import { useState } from 'react';
import {ContactUs} from "./components/ContactUs";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      {process.env.NODE_ENV === 'development' && (
        <div className="bg-white pl-10 pt-10">
          <p>APENAS DEVS!</p>
          <p>Algo deu errado:</p>
          <pre>{error.message}</pre>
          <button onClick={resetErrorBoundary}>Tente novamente</button>
        </div>
      )}
      <NotFound resetErrorBoundary={resetErrorBoundary} />
    </div>
  );
}

const browserHistory = createBrowserHistory();

function App() {
  const [showContact, setShowContact] = useState(false);

  function toggleContactUs() {
    setShowContact(!showContact);
  }

  return (
    <BrowserRouter history={browserHistory}>
      <Header />
      <ContactUs
        showContact={showContact}
        setShowContact={setShowContact}
        toggleContactUs={toggleContactUs}
      />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AppRoutes />
      </ErrorBoundary>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
