import { Faq } from './Faq';

export const ApplyPage = () => {
  return (
    <>
      <section data-id="hero" className="mb-48 px-4 lg:px-8">
        <div className="mx-auto container flex flex-col items-center gap-4 text-center w-2/3 lg:1/2">
          <i className="fa fas fa-paperclip text-3xl" />
          <h5 className="text-p md:text-h5 text-neutral-600">
            Preencha esse{' '}
            <a
              href="https://forms.gle/Chx2BviMhG71KEiv7"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              formulário
            </a>{' '}
            para aplicar.
          </h5>
          <p className="text-p">São apenas 7 perguntas:</p>
          <p className="text-p">
            Nome, Email, WhatsApp, Empresa, Cargo, Faturamento, Indústria e Onde
            Mora.
          </p>
          <p className="text-p">
            Nos vemos na entrevista!
          </p>
        </div>
      </section>

      <Faq />
    </>
  );
};
