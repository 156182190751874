import { getHrefWithSameQueryParams, getRandomOption } from '../utilities';
import { Faq } from './Faq';
import { RoutePaths } from '../RoutePaths';
import { Link } from 'react-router-dom';

const possibleFoundersWording = ['founders', 'fundadores'];

const founders = getRandomOption(possibleFoundersWording, 'founders');

export const HomePage = () => {
  const features = [
    {
      icon: 'fa-people-arrows',
      title: 'Wolf Pack',
      text: 'Sub-grupos de founders que se reúnem mensalmente para compartilhar experiências e desafios.',
    },
    {
      icon: 'fa-people-carry',
      title: 'Comunidade Online',
      text: 'Ambiente de fórum para troca constante de conhecimento, além de Newsletter semanal.',
    },
    {
      icon: 'fa-bullhorn',
      title: 'Eventos Presenciais',
      text: 'Encontros exclusivos e presenciais nas cidades mais importantes do Brasil.',
    },
  ];

  return (
    <>
      <section data-id="hero" className="mb-24 px-4 py-10 lg:px-8 bg-bege-2">
        <div className="mx-auto container flex flex-col lg:flex-row gap-12">
          <div className="flex flex-col gap-8 basis-[50%]">
            <h1 className="text-h3 md:text-h2 lg:text-h1 font-stretch-95 text-neutral-700">
              A comunidade que une <span className="font-bold">{founders}</span>{' '}
              de sucesso
            </h1>
            <h5 className="text-h6 md:text-h5">
              Alone™ é uma comunidade para{' '}
              <b className="font-semibold">{founders} selecionados </b>
              se conectarem. Exclusiva para founders, CEOs e C-levels.
            </h5>
            <div className="flex flex-col items-start pt-4">
              <Link
                to={getHrefWithSameQueryParams(RoutePaths.APPLY)}
                className="btn-primary mb-6"
              >
                Aplicar agora
              </Link>
              <p className="flex gap-2 mb-2">
                <i className="far fa-video text-sm pt-0.5 mr-0.5" />
                Entrevista por vídeo-chamada
              </p>
              <p className="flex gap-2">
                <i className="far fa-calendar-check text-sm pt-0.5 mr-1.5" />
                Feedback em 15 dias
              </p>
            </div>
          </div>
          <div className="basis-[50%] flex flex-col justify-center items-center lg:items-end">
            <span>
              <img src="/images/wolf-pack-sm.png" alt="Alone" />
            </span>
          </div>
        </div>
      </section>

      <section data-id="pitch" className="mb-24 px-4 py-10 lg:px-8">
        <div className="mx-auto container flex flex-col gap-8">
          <h4 className="text-h4">
            O dia-a-dia na liderança de uma empresa pode ser solitário.
          </h4>
          <p className="text-p">
            Você precisa tomar decisões difíceis todos os dias, com informações
            imprecisas e problemas que provavelmente nunca enfrentou antes. É
            assustador.
          </p>
          <p className="text-p">
            Em nossa experiência, a melhor maneira de superar esses desafios é
            aprender com as vitórias e erros de outras pessoas, e rodear-se de
            pessoas que já estiveram lá e passaram por isso.
          </p>
          <p className="text-p">
            Esteja você enfrentando tudo isso em São Paulo ou no Rio de Janeiro,
            ou do seu home-office em algum lugar do Brasil, encontrar pessoas
            como você é difícil. Mas quando você as encontra, sua vida e sua
            carreira mudam.
          </p>
          <p className="text-p">E é para isso que existe a Alone™.</p>
          <p className="text-p">
            Sabemos o quanto é chato quando você está buscando ajuda sobre um
            tópico em um certo nível e só encontra respostas de um nível
            completamente diferente, por exemplo, você quer discutir sobre os
            desafios de escalar um produto de 1M para 10M de ARR e a resposta
            vem de um founder que está buscando Product-Market Fit de seu
            primeiro produto ou ainda tentando recrutar sua primeira pessoa de
            produto.
          </p>
          <p className="text-p">
            Nós selecionamos criteriosamente as pessoas e criamos os Wolf Packs
            para que isso não ocorra. Você estará a frente de alguns em
            vários aspectos porém atrás em outros. Assim a troca de
            conhecimento é benéfica para todos.
          </p>
          <p className="text-p">
            Ao se juntar a Alone™, você deixa de ser uma pessoa solitária em
            busca do sucesso e tem acesso imediato a uma alcateia de pessoas que
            estão passando pelas mesmas coisas que você. Junte-se a nós e mude a
            sua vida para sempre.
          </p>
        </div>
      </section>
      <section data-id="features" className="mb-24 px-4 lg:px-8">
        <div className="mx-auto container flex flex-col lg:flex-row gap-8">
          {features.map(({ icon, title, text }) => (
            <div data-id="feature" className="flex gap-6">
              <div className="basis-[50px]">
                <i className={`fa ${icon} text-4xl text-primary-500`}></i>
              </div>
              <div className="flex flex-col gap-4">
                <h6 className="text-h6 text-neutral-700 font-semibold">
                  {title}
                </h6>
                <p className="text-p">{text}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section data-id="info" className="mb-24 px-4 py-10 lg:px-8">
        <div className="mx-auto container flex flex-col gap-8 justify-center text-center">
          <p className="text-p">
            Envie dúvidas para{' '}
            <span className="underline">alone@alone.dev</span> e nós
            responderemos imediatamente
          </p>
        </div>
      </section>

      <Faq />
    </>
  );
};
