import { Link, useLocation } from 'react-router-dom';
import { RoutePaths } from '../RoutePaths';
import { useState } from 'react';
import { getHrefWithSameQueryParams } from '../utilities';

export function Faq() {
  const location = useLocation();
  const faqs = [
    {
      question: 'O que é a Alone™?',
      text: 'A Alone™ é uma comunidade para founders, CEOs e C-levels exclusivamente selecionados. Os candidatos devem cumprir os critérios de seleção e passam por uma entrevista por vídeo. Menos de 12% das aplicações são aceitas. A Alone™ foi criada por founders que sentiam a necessidade de trocar experência em um nível mais profundo com outras pessoas passando pelos mesmos desafios.',
    },
    {
      question: 'Quem faz parte?',
      text: 'É necessário ter um faturamento anual recente de pelo menos R$ 5M, ou R$ 10M em investimentos levantados ou exits. A maioria dos membros faz parte de empresas baseadas em tecnologia.',
    },
    {
      question: 'Como eu aplico?',
      text: (
        <span>
          {location.pathname !== RoutePaths.APPLY ? (
            <span>
              Qualquer um pode{' '}
              <Link to={getHrefWithSameQueryParams(RoutePaths.APPLY)}>
                aplicar
              </Link>
            </span>
          ) : (
            'Enviando o formulário acima'
          )}
          . Se estiver dentro dos requisitos básicos será agendada uma
          entrevista por vídeo-chamada. Sendo aprovado na vídeo-chamada em até 3
          dias estará dentro da nossa comunidade.
        </span>
      ),
    },
    {
      question: 'O que é o Wolf Pack?',
      text: 'WP é um sub-grupo de 6-10 membros que se reúnem mensalmente em vídeo-chamadas com um facilitador profissional para que possam compartilhar experiências e desafios. O objetivo é que os membros se sintam mais próximos e possam se ajudar mutuamente. Fazendo um paralelo com lobos: a cada momento alguém estará falando mas toda a alcateia estará atenta em como podem ajudar, no meio dessa experiência todos estão aprendendo ao mesmo tempo, tornando a alcateia cada vez mais forte.',
    },
    {
      question: 'Quando será o próximo evento presencial?',
      text: 'Entre 19/abril e 2/maio de 2023. Provavelmente em São Paulo. Assim que a data for definida avisaremos os membros da região.',
    },
    {
      question: 'Top, mais algum benefício?',
      text: 'Estamos em fase de negociação de parcerias. Assim que tivermos novidades avisaremos.',
    },
  ];

  const [activeQuestion, setActiveQuestion] = useState('');
  const toggleActiveQuestion = (question) => () =>
    setActiveQuestion(question === activeQuestion ? '' : question);
  return (
    <section data-id="faqs" className="px-4 lg:px-8 mb-24">
      <div className="container mx-auto 2xl:max-w-default flex flex-col lg:flex-row gap-12">
        <div data-id="faqs-title" className="shrink-0 grow-0 lg:basis-[460px]">
          <h3 className="text-h4 md:text-h3 font-medium mb-2 text-center lg:text-left">
            Perguntas frequentes
          </h3>
        </div>
        <div id="faqs-list" className="flex-auto">
          <ul className="flex flex-col gap-6">
            {faqs.map(({ question, text }) => (
              <li key={question} className="pb-6 border-b border-neutral_200">
                <div
                  className="flex gap-8 flex-nowrap cursor-pointer"
                  onClick={toggleActiveQuestion(question)}
                >
                  <h6 className="text-h6 font-semibold grow">{question}</h6>
                  <button>
                    <i
                      className={`fa fas fa-chevron-down ${
                        activeQuestion === question ? 'rotate-180' : ''
                      }`}
                    />
                  </button>
                </div>
                <p
                  className={`text-p mt-4 ${
                    activeQuestion === question ? '' : 'hidden'
                  }`}
                >
                  {text}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
