const Footer = () => {
  return (
    <>
      <footer data-id="footer" className="px-4 lg:px-8 pb-12 ">
        <div className="container mx-auto 2xl:max-w-default flex flex-col sm:flex-row text-small text-center">
          <p className="grow pb-4">© 2023 Alone by Quave. Todos os direitos reservados.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
