// curl "http://localhost:3000/api/sendFeedbackEmail" -X POST -H "Accept: application/json" -H "Content-Type: application/json" -d '{"fromEmail": "client@example.com", "fromName": "client", "content": "Olá, preciso de ajuda! Teste Filipe", "to": "contato@quave.com.br",     "from": "contato@quave.com.br", "subject": "Contact Quave Site"}'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../RoutePaths';
import {getHrefWithSameQueryParams} from "../utilities";
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const ContactUs = ({ showContact, setShowContact, toggleContactUs }) => {
  const [fromEmail, setFromEmail] = useState('');
  const [fromName, setFromName] = useState('');
  const [content, setContent] = useState('');

  const onClose = () => {
    reset();
    toggleContactUs();
  };

  function reset() {
    setFromEmail('');
    setFromName('');
    setContent('');
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isValidEmail()()) {
      return;
    }
    let body = JSON.stringify({
      fromEmail,
      fromName: fromName || 'Unknown',
      content: content || 'No content',
      to: 'contato@quave.com.br',
      from: 'contato@quave.com.br',
      subject: `Contact to Quave - ${fromName} (${fromEmail})`,
    });

    // it needs to have www, otherwise cloudfront is blocking it
    fetch('https://www.codeftw.dev/api/sendEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    })
      .then(() => {
        reset();
      })
      .catch((e) => {
        console.error(`Error sending email`, e);
      });
  };

  const isValidEmail = (fromEmailChanged) => () => {
    const email = fromEmailChanged || fromEmail;

    if (!email) {
      return false;
    }

    return validateEmail(email);

  };


  return (
    <div
      className={`fixed top-0 right-0 bottom-0 left-0 bg-black_A70 z-10 md:flex-center p-4 lg:p-8 overflow-auto ${
        showContact ? 'md:flex' : 'hidden'
      }`}
    >
      <section className="bg-white max-w-[900px] min-h-[450px] rounded-8 flex items-stretch flex-col md:flex-row md:overflow-hidden sh-large relative">
        <button
          className="absolute top-[20px] right-[20px] p-2"
          onClick={onClose}
        >
          <i className="fa fas fa-times text-xl"></i>
        </button>
        <form onSubmit={onSubmit} className="p-6 md:p-8 flex flex-col gap-4">
          <h4 className="text-h4 font-semibold text-neutral_300 mb-2">
            Compre agora o seu Alone™
          </h4>
          <p className="text-p">
            Faça a reserva do seu aparelho e seja um dos primeiros a experimentar a
            tranquilidade de ter em mãos informações precisas e confiáveis sobre o seu botijão de gás.

          </p>

          <div className="flex flex-col xsm:flex-row flex-nowrap xsm:items-center gap-4 py-4">
            <span className="text-p font-medium grow">Alone</span>
            <label className="flex flex-nowrap gap-2 items-center">
              <select
                name="a"
                id="a"
                className="border border-neutral-300 rounded-2 text-p p-2 rounded-4 w-full xsm:w-auto min-w-[5rem]"
              >
                <option value="1">1</option>
                <option value="1">2</option>
                <option value="1">3</option>
                <option value="1">4</option>
                <option value="1">5</option>
              </select>
              <span className="text-p">un.</span>
            </label>
            <span className="text-p font-medium">R$199,99</span>
          </div>

          <p className="text-small">
            * A entrega levará no máximo 30 dias.
          </p>

          <button className="btn-primary w-full mb-4" onClick={toggleContactUs}>
            Compre agora
          </button>

          <div className="flex flex-col sm:flex-row items-start xsm:items-center justify-center gap-4 sm:gap-8">
            <p className="flex gap-2">
              <i className="far fa-lock text-sm pt-0.5" />
              Pagamento seguro pelo Stripe
            </p>
            <p className="flex gap-2">
              <i className="far fa-shield-check text-sm pt-0.5" />
              Entrega em até 30 dias
            </p>
          </div>

          <p className="text-small hidden">
            Ao submeter essas informações, você concorda com os {' '}
            <Link
              to={getHrefWithSameQueryParams(RoutePaths.TERMS_AND_CONDITIONS)}
              className="text-primary_500"
            >
              Termos e Condições
            </Link>{' '}
            da Quave e a nossa {' '}
            <Link to={getHrefWithSameQueryParams(RoutePaths.PRIVACY_POLICY)} className="text-primary_500">
              Política de Privacidade
            </Link>
            .
          </p>
        </form>
      </section>
    </div>
  );
};
