import React, {useEffect} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import { HomePage } from './pages/Home';
import { NotFoundPage } from './pages/NotFound';
import {RoutePaths} from "./RoutePaths";
import {ApplyPage} from "./pages/ApplyPage";
import {OgPage} from "./pages/OgPage";

export const AppRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname])
  return (
    <Routes>
      <Route
        path={RoutePaths.HOME}
        element={<HomePage />}
      />
      <Route path={RoutePaths.APPLY} element={<ApplyPage />} />
      <Route path={RoutePaths.OG} element={<OgPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
