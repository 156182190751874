import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RoutePaths } from '../../RoutePaths';
import {
  getHrefWithSameQueryParams,
} from '../../utilities';

const Header = () => {
  const location = useLocation();
  return (
    <>
      <section
        data-id="header"
        className="px-4 py-4 lg:py-6 sticky top-0 z-10 bg-bege"
      >
        <div className="container 2xl:max-w-default mx-auto flex gap-6 items-center">
          <Link
            to={getHrefWithSameQueryParams(RoutePaths.HOME)}
            data-id="logo"
            className="block"
          >
            <img
              className="block h-24 w-24"
              src="/images/wolf-sm.png"
              alt="Alone Logo"
            />
          </Link>
          <nav className="grow flex justify-end items-center">
            <ul
              data-id="desktop-nav"
              className="fle flex-col items-center gap-4"
            >
              <li>
                {location.pathname !== RoutePaths.APPLY && (
                  <Link
                    to={getHrefWithSameQueryParams(RoutePaths.APPLY)}
                    className="btn-primary whitespace-nowrap"
                  >
                    Aplicar
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </section>
    </>
  );
};

export default Header;
