export const OgPage = () => {
  return (
    <div className="flex flex-col items-center justify-center w-[1200px] h-[600px] bg-bege-2 pt-5">
      <div>
        <h1 className="text-h2 font-stretch-95 text-neutral-700 text-center pt-5">
          Alone™
        </h1>
      </div>
      <div className="w-1/2">
        <img src="/images/wolf-pack-sm.png" alt="Alone" />
      </div>
    </div>
  );
};
